import type { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { api } from '../client';
import React from 'react';
import { UserContext } from '../context/userContext';
import { InterestRes } from '../types/interestTypes';

const getInterest = () => {
  return api.get<AxiosError, InterestRes>('/v1/interest');
};

export const interestService = () => {
  const { loggedIn } = React.useContext(UserContext);

  const interestList = useQuery({
    queryKey: ['interestList'],
    queryFn: getInterest,
    enabled: loggedIn,
  });

  return {
    interestList: interestList.data?.data.interests,
  };
};
