import * as React from 'react';
import {
  Alert,
  AlertTitle,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { UserContext } from '../context/userContext';
import { userService } from '../api';

import {
  FormContainer,
  TextFieldElement,
  useForm,
  SelectElement,
} from 'react-hook-form-mui';
import { UserType } from '../types';

interface Props {
  registerButtonRef?: React.RefObject<HTMLButtonElement>;
}

interface FormInputs {
  first_name: string;
  last_name: string;
  email: string;
  company: string;
  user_type: UserType;
  password: string;
}

const typeOptions = [
  {
    id: 'REGULAR',
    label: 'Regular user',
  },
  {
    id: 'SME',
    label: 'SME user',
  },
];

export const Register: React.FC<Props> = ({ registerButtonRef }) => {
  const { registerNewUser } = userService();
  const formContext = useForm<FormInputs>({
    defaultValues: {
      first_name: '',
      last_name: '',
      company: '',
      user_type: 'REGULAR',
      password: '',
    },
  });
  const [showForm, setShowForm] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const { handleSubmit, reset, setError } = formContext;

  const handleOpen = () => setShowForm(true);
  const handleClose = () => {
    setShowForm(false);
    setTimeout(() => {
      setShowSuccess(false);
      reset();
    }, 100);
  };

  const handleRegister = (data: FormInputs) => {
    registerNewUser(data)
      .then(() => {
        setShowSuccess(true);
      })
      .catch((ajaxError) => {
        const error = ajaxError?.response?.data;
        const message: string = error?.debug_message?.errorMessage || '';
        console.error('New user register error: ', error?.debug_message);

        switch (true) {
          case message.includes('email'):
            setError('email', { message });
            break;

          case message.includes('Password'):
            setError('password', { message });
            break;

          default:
            break;
        }
      });
  };

  const { loggedIn } = React.useContext(UserContext);

  return (
    <>
      {!loggedIn && (
        <Button
          ref={registerButtonRef}
          color="inherit"
          onClick={handleOpen}
          variant="text"
        >
          Register
        </Button>
      )}

      <Dialog onClose={handleClose} open={showForm}>
        <DialogTitle>Register</DialogTitle>

        <FormContainer
          formContext={formContext}
          handleSubmit={handleSubmit(handleRegister)}
        >
          <Collapse in={!showSuccess} timeout="auto" unmountOnExit>
            <DialogContent>
              <Stack spacing={2}>
                <DialogContentText id="alert-dialog-description">
                  Welcome, please fill all required fields to register.
                </DialogContentText>
                <TextFieldElement
                  fullWidth
                  // TODO: add FE validation of email
                  name="email"
                  label="Email address"
                  required
                  type="email"
                />
                <TextFieldElement
                  fullWidth
                  name="password"
                  label="Password"
                  required
                />
                <Stack direction="row" spacing={2}>
                  <TextFieldElement
                    fullWidth
                    name="first_name"
                    label="First name"
                    required
                  />
                  <TextFieldElement
                    fullWidth
                    name="last_name"
                    label="Last name"
                    required
                  />
                </Stack>

                <TextFieldElement
                  fullWidth
                  name="company"
                  label="Company"
                  required
                />

                <SelectElement
                  name="user_type"
                  label="Type"
                  fullWidth
                  options={typeOptions}
                  required
                />
              </Stack>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose}>Close</Button>

              <Button variant="contained" type="submit">
                Register
              </Button>
            </DialogActions>
          </Collapse>
          <Collapse in={showSuccess} timeout="auto" unmountOnExit>
            <DialogContent>
              <Alert severity="success">
                <Stack spacing={2}>
                  <AlertTitle>
                    You have successfully register your account.
                  </AlertTitle>
                  <Typography variant="body2">
                    Please check your email for confirmation link.
                  </Typography>
                  <Typography variant="body2">
                    Additional note, when confirmation page is shown you need to
                    press <strong>Click here to proceed</strong> button as shown
                    in image.
                  </Typography>

                  <img
                    width={450}
                    src={window.APP_CONFIG?.REACT_APP_ASSETS_URL ? `${window.APP_CONFIG.REACT_APP_ASSETS_URL}/images/confirmation.jpg` : `${process.env.PUBLIC_URL}/confirmation.jpg`}
                  ></img>
                </Stack>
              </Alert>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleClose}>
                Close
              </Button>
            </DialogActions>
          </Collapse>
        </FormContainer>
      </Dialog>
    </>
  );
};
