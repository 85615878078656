import * as React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SourceDocument } from '../types';
import ReactMarkdown from 'react-markdown';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { TitleSection } from './Common';

interface Props {
  sourceDocuments: SourceDocument[] | null;
}

export const References: React.FC<Props> = ({ sourceDocuments }) => {
  if (!sourceDocuments) {
    return null;
  }

  return (
    <TitleSection
      title="References:"
      icon={<LibraryBooksIcon fontSize="large" />}
    >
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Stack direction="row" spacing={2}>
            <p>
              {sourceDocuments.length} references available, expand for details.
            </p>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          {sourceDocuments.map(({ page, page_content, source }, i) => {
            return (
              <Accordion
                key={i}
                sx={{
                  '& p, & strong': {
                    //use sx and select related class
                    fontSize: '14px', // change its style
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Stack direction="row" spacing={2}>
                    <PictureAsPdfIcon fontSize="small" color="action" />
                    <p>{source}</p>
                    <strong>page {page}</strong>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <ReactMarkdown>{page_content}</ReactMarkdown>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </AccordionDetails>
      </Accordion>
    </TitleSection>
  );
};
