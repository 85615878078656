import * as React from 'react';
import { List, ListItemButton, ListItemText } from '@mui/material';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { TitleSection } from './Common';

interface Props {
  questions: string[] | null;
  onClick: (question: string) => void;
}

export const FollowUpQuestions: React.FC<Props> = ({ questions, onClick }) => {
  if (!questions) {
    return null;
  }

  const handleFollowUpQuestion = (question: string) => () => {
    onClick(question);
  };

  return (
    <TitleSection
      title="Follow up question(s):"
      icon={<QuestionAnswerIcon fontSize="large" />}
    >
      <List dense>
        {questions.map((question, i) => (
          <ListItemButton onClick={handleFollowUpQuestion(question)} key={i}>
            <ListItemText primary={question} />
          </ListItemButton>
        ))}
      </List>
    </TitleSection>
  );
};
