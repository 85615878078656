import * as React from 'react';
import { Box, Chip, Stack, Typography } from '@mui/material';

interface Props extends React.PropsWithChildren {
  title: string;
  chips?: string[];
  icon?: React.ReactElement;
}

export const TitleSection: React.FC<Props> = ({
  title,
  chips,
  icon,
  children,
}) => {
  if (!title) {
    return null;
  }

  return (
    <Box>
      <Stack direction="row" marginBottom={2} alignItems="center" gap={1}>
        {icon}

        <Typography variant="h6">{title}</Typography>

        {chips &&
          chips.map((chipLabel) => (
            <Chip
              key={chipLabel}
              label={chipLabel}
              size="small"
              variant="outlined"
            />
          ))}
      </Stack>
      {children}
    </Box>
  );
};
