import * as React from 'react';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { ConversationMenu } from './ConversationMenu';
import { ReviewsMenu } from './ReviesMenu';
import theme from '../theme';
import { Login } from './Login';
import { NavLink, useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../icons/logo.svg';
import MenuIcon from '@mui/icons-material/Menu';
import { Register } from './Register';
import SettingsIcon from '@mui/icons-material/Settings';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

export const SideMenu: React.FC = () => {
  const navigate = useNavigate();
  const [showSideBar, setShowSideBar] = React.useState(true);
  const registerButtonRef = React.useRef<HTMLButtonElement>(null);
  const drawerWidth = showSideBar ? 280 : 0;

  const toggleSidebar = () => {
    setShowSideBar(!showSideBar);
  };

  const handleNewConversation = () => {
    navigate('/');
  };
  return (
    <>
      <AppBar
        color="blue"
        sx={{
          zIndex: theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <div onClick={handleNewConversation}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleSidebar}
              >
                <MenuIcon />
              </IconButton>
              <Logo cursor="pointer" height={28} width={40} fill="#fff" />
              <Typography
                sx={{
                  cursor: 'pointer',
                }}
                noWrap
                variant="h6"
              >
                Industrial Knowledge Manager
              </Typography>
              <Typography
                sx={{
                  opacity: 0.5,
                  paddingLeft: 1,
                }}
                variant="subtitle1"
              >
                v{process.env.REACT_APP_VERSION}
              </Typography>
            </Box>
          </div>
          <Stack spacing={2} direction="row">
            <Login
              onRegisterClick={() => {
                registerButtonRef.current?.click();
              }}
            />
            <Register registerButtonRef={registerButtonRef} />
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            background: '#101827',
            color: 'white',
          },
          '& .MuiSvgIcon-root': {
            fill: 'white',
          },

          '& .MuiListItem-root': {
            color: 'white',
          },
          '& .MuiListItem-root.active': {
            color: '#101827',
            '& .MuiSvgIcon-root': {
              fill: '#101827',
            },
          },
        }}
        variant="persistent"
        anchor="left"
        open={showSideBar}
      >
        <Toolbar />

        <Divider />
        <Button
          sx={{ color: 'white', margin: '0 8px' }}
          color="inherit"
          variant="outlined"
          startIcon={<QuestionAnswerIcon />}
          onClick={handleNewConversation}
        >
          New conversation
        </Button>

        <ConversationMenu />
        <ReviewsMenu />
        <List
          sx={{
            '.active': {
              bgcolor: '#dfdfdf',
            },
          }}
        >
          <ListItem component={NavLink} to={'/settings'}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};
