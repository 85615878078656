// https://elazizi.com/posts/react-query-auth-token-refresh/#simple-project-setup
import type { AxiosError } from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';

import { api } from '../client';
import {
  GetUserInterestRes,
  UserInterestReq,
  UserInterestRes,
  UserMeRes,
  UserReq,
  UserRes,
  UserSmeRes,
} from '../types/';
import React from 'react';
import { UserContext } from '../context/userContext';
import { enqueueSnackbar } from 'notistack';

const postRegisterUser = (reqData: UserReq) => {
  return api.post<AxiosError, UserRes>(`/v1/user`, reqData);
};

const puthUserInterest = (reqData: UserInterestReq) => {
  return api.put<AxiosError, UserInterestRes>(`/v1/user/interest`, reqData);
};

// const deleteUserInterest = (reqData: UserInterestReq) => {
//   return api.delete<AxiosError, UserInterestRes>(`/v1/user/interest`, reqData);
// };

// UserInterestReq is ok
const getUserInterest = () => {
  return api.get<AxiosError, GetUserInterestRes>('/v1/user/interest');
};

const getSmeUsers = () => {
  return api.get<AxiosError, UserSmeRes>('/v1/user/sme');
};

const getUserInfo = () => {
  return api.get<AxiosError, UserMeRes>('/v1/user/me');
};

export const userService = () => {
  const { accessToken, loggedIn } = React.useContext(UserContext);

  const smeUsersList = useQuery({
    queryKey: ['smeUsersList'],
    queryFn: getSmeUsers,
    enabled: loggedIn,
  });

  const userInterest = useQuery({
    queryKey: ['userInterest'],
    queryFn: getUserInterest,
    enabled: loggedIn,
  });

  const userInfo = useQuery({
    queryKey: ['userInfo', accessToken],
    queryFn: getUserInfo,
    enabled: !!accessToken,
  });

  const registerUserMutation = useMutation({
    mutationFn: postRegisterUser,
    // handled on UI
    // onError: ({ message, name, cause }) => {
    //   enqueueSnackbar(`Register user error: ${name} ${message} ${cause}`, {
    //     variant: 'error',
    //   });
    // },
  });
  const updateUserInterest = useMutation({
    mutationFn: puthUserInterest,
    onError: ({ message }) => {
      enqueueSnackbar(`updateUserInterest error:  ${message}`, {
        variant: 'error',
      });
    },
  });

  // TODO: add error handling
  // if (smeUsersList.error && smeUsersList.errorUpdateCount === 1) {
  //   enqueueSnackbar(`getSmeUsers error: ${smeUsersList.error.message}`, {
  //     variant: 'error',
  //   });
  // }

  return {
    smeUsersList: smeUsersList.data?.data.smes,
    userInfo: userInfo.data?.data,
    userInterest: userInterest.data?.data.interests,
    registerNewUser: registerUserMutation.mutateAsync,
    updateUserInterest: updateUserInterest.mutateAsync,
    isSME: userInfo.data?.data.user_type === 'SME',
  };
};
