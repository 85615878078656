import * as React from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Stack,
} from '@mui/material';
import { userService, reviewService } from '../api';
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
  useForm,
} from 'react-hook-form-mui';
import { useNavigate } from 'react-router-dom';

interface FormInputs {
  comment: string;
  smeId: number;
}
interface Props {
  quid: string;
  reviewUid: string | null;
}

export const ContactSme: React.FC<Props> = ({ quid, reviewUid }) => {
  const [isFormExpanded, setIsFormExpanded] = React.useState(false);
  const navigate = useNavigate();

  const formContext = useForm<FormInputs>({
    defaultValues: {
      comment: '',
    },
  });

  const { handleSubmit, reset, clearErrors } = formContext;

  const { smeUsersList } = userService();
  const { sendReview, requestedReviewsRefetch } = reviewService();

  const smeSelectOptions = smeUsersList?.map(
    ({ first_name, last_name, id }) => ({
      id,
      label: `${first_name} ${last_name}`,
    }),
  );

  const handleSubmitReview = ({ comment, smeId }: FormInputs) => {
    sendReview({
      qa_uid: quid,
      requester_note: comment,
      reviewer_id: smeId,
    }).then(() => {
      requestedReviewsRefetch();
    });
  };

  const handleExpand = () => {
    setIsFormExpanded(true);
  };

  const handleViewReview = () => {
    navigate(`/review/${reviewUid}`);
  };

  const handleCollapse = () => {
    setIsFormExpanded(false);
    clearErrors();
  };

  return (
    <FormContainer
      formContext={formContext}
      handleSubmit={handleSubmit(handleSubmitReview)}
    >
      <Collapse in={!isFormExpanded} timeout="auto" unmountOnExit>
        <Box justifyContent="center" display="flex">
          {reviewUid && (
            <Button onClick={handleViewReview} variant="contained">
              View Review
            </Button>
          )}
          {!reviewUid && (
            <Button onClick={handleExpand} variant="contained">
              Contact SME
            </Button>
          )}
        </Box>
      </Collapse>

      <Collapse in={isFormExpanded} timeout="auto" unmountOnExit>
        <Box justifyContent="center" display="flex" width="100%">
          <Card elevation={2} sx={{ width: '80%' }}>
            <CardHeader title="Contact SME" />

            <CardContent>
              <Stack spacing={2}>
                <SelectElement
                  name="smeId"
                  label="SMEs by domain"
                  fullWidth
                  options={smeSelectOptions}
                  required
                />

                <TextFieldElement
                  name="comment"
                  label="Comment"
                  fullWidth
                  required
                  rows={4}
                  about=""
                  multiline
                ></TextFieldElement>
              </Stack>
            </CardContent>
            <CardActions>
              <Button onClick={handleCollapse} size="small">
                Close
              </Button>
              <Button
                variant="contained"
                // onClick={handleContactSme}
                size="small"
                type="submit"
              >
                Contact SME
              </Button>
            </CardActions>
          </Card>
        </Box>
      </Collapse>
    </FormContainer>
  );
};
