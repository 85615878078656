import * as React from 'react';
import { UserContext } from '../context/userContext';
import { conversationService, promptService } from '../api';
import Box from '@mui/material/Box';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  Toolbar,
} from '@mui/material';
import { BotInput } from '../molecules/BotInput';
import zIndex from '@mui/material/styles/zIndex';

type VectorsType = string[];

export const Home: React.FC = () => {
  const { loggedIn } = React.useContext(UserContext);
  const { vectorStoresList } = conversationService();

  const { sendQuestion, questionIsPending } = promptService();
  const [showVectorSelection, setShowVectorSelection] = React.useState(true);
  const [selectedVectors, setSelectedVectors] = React.useState<VectorsType>();

  const handleSelectVector = (event: any, value: VectorsType) => {
    setSelectedVectors(value);
  };

  const vectorOptions = vectorStoresList || [];

  const handleConfirm = () => {
    setShowVectorSelection(false);
  };

  return loggedIn ? (
    <Box
      component="main"
      sx={{
        minHeight: '100vh',
      }}
    >
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          p: 3,
        }}
      >
        <Toolbar />
        <BotInput
          loading={questionIsPending}
          chips={selectedVectors}
          onSubmit={(value) => {
            if (selectedVectors) {
              sendQuestion({ prompt: value, chroma_db_names: selectedVectors });
            }
          }}
        />
      </Box>

      <Dialog
        sx={{
          zIndex: zIndex.drawer - 1, // Enable other actions
        }}
        open={showVectorSelection}
      >
        <DialogTitle>Please select source docs</DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <DialogContentText>
              All answers in this Conversation will be based on the selected
              sources. Please note that at least one source has to be selected.
            </DialogContentText>

            <Autocomplete
              multiple
              id="vector-selector"
              options={vectorOptions}
              filterSelectedOptions
              onChange={handleSelectVector}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Source docs"
                  placeholder="Select source docs"
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            disabled={!selectedVectors?.length}
            variant="contained"
            onClick={handleConfirm}
          >
            Confirm Selection
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  ) : null;
};
