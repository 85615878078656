import * as React from 'react';
import Box from '@mui/material/Box';
import { UserContext } from '../context/userContext';
import { Paper, Stack, Toolbar, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import { conversationService } from '../api';
import ReactMarkdown from 'react-markdown';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { ConversationItemQas } from '../types';
import { References } from '../molecules/References';
import { ContactSme } from '../molecules/ContactSme';
import { BotInput } from '../molecules/BotInput';
import { FollowUpQuestions } from '../molecules/FollowUpQuestions';
import { InconclusiveAnswer } from '../molecules/InconclusiveAnswer';
import { ListSkeleton } from '../molecules/Skeletons';
import { TitleSection } from '../molecules/Common';
import { grey } from '@mui/material/colors';
import { FeedBack } from '../molecules/Feedback';

interface Props {
  uid?: string | null;
}

export const Conversation: React.FC<Props> = ({ uid }) => {
  const { loggedIn } = React.useContext(UserContext);
  const listRef = useRef<HTMLDivElement>(null);

  const { conversationId = uid } = useParams();
  const isInlineView = !!uid;

  if (!conversationId) {
    return null;
  }

  const {
    getConversationQas,
    sendQuestion,
    conversationResponse,
    conversationIsPending,
    questionResponse,
    questionIsPending,
  } = conversationService();

  const { qas: conversationQas, chroma_db_filenames: chips } =
    conversationResponse || {};

  const [askedQuestion, setAskedQuestion] = React.useState('');
  const [currentSessionQuestions, setCurrentSessionQuestions] = React.useState<
    ConversationItemQas[]
  >([]);

  const handleUpdate = () => {
    getConversationQas({ uid: conversationId });
  };

  useEffect(() => {
    getConversationQas({ uid: conversationId });
    // Clear state
    setCurrentSessionQuestions([]);
  }, [conversationId]);

  useEffect(() => {
    if (questionResponse && currentSessionQuestions) {
      setCurrentSessionQuestions([
        ...currentSessionQuestions,
        questionResponse,
      ]);
    }
  }, [questionResponse]);

  // After question returned
  useEffect(() => {
    listRef.current?.lastElementChild?.scrollIntoView({
      behavior: 'smooth',
    });
    setAskedQuestion('');
  }, [conversationQas, currentSessionQuestions]);

  const handleSubmitQuestion = (question: string) => {
    setAskedQuestion(question);
    listRef.current?.lastElementChild?.scrollIntoView({
      behavior: 'smooth',
    });

    sendQuestion({ uid: conversationId, prompt: question });
  };

  const handleFollowUpQuestion = handleSubmitQuestion;

  const allAnswers = [
    ...currentSessionQuestions,
    ...(conversationQas || []),
  ].sort(({ created_at: cr1 }, { created_at: cr2 }) => {
    return cr1.localeCompare(cr2);
  });

  return loggedIn ? (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
      bgcolor={grey[300]}
    >
      <Toolbar />
      {conversationIsPending && (
        <Paper>
          <Stack padding={2}>
            <ListSkeleton numOfItems={12} width="100%" />
          </Stack>
        </Paper>
      )}
      {!conversationIsPending && (
        <Stack spacing={2}>
          <Stack spacing={2} ref={listRef}>
            {allAnswers?.map(
              ({
                answer,
                question,
                uid: quid,
                source_documents,
                review_uid,
                follow_up_questions,
                annotations,
                feedback_lickert,
                feedback_comment,
              }) => (
                <Paper>
                  <Stack padding={2} spacing={3} key={quid} gap={1}>
                    <TitleSection
                      title="Question:"
                      chips={chips}
                      icon={<AccountCircleIcon fontSize="large" />}
                    >
                      <Typography fontSize={18} variant="body1">
                        {question}
                      </Typography>
                    </TitleSection>

                    <TitleSection
                      title="Answer:"
                      // chips={['Internal docs', 'Gov docs']} // new feature ready
                      icon={<LocalPoliceIcon fontSize="large" />}
                    >
                      <ReactMarkdown key={quid}>{answer}</ReactMarkdown>
                    </TitleSection>

                    <FeedBack
                      uid={conversationId}
                      quid={quid}
                      defaultValue={feedback_lickert}
                      defaultComment={feedback_comment}
                    />
                    <FollowUpQuestions
                      questions={follow_up_questions}
                      onClick={handleFollowUpQuestion}
                    />

                    <References sourceDocuments={source_documents} />

                    <InconclusiveAnswer annotation={annotations} />

                    <ContactSme quid={quid} reviewUid={review_uid} />
                  </Stack>
                </Paper>
              ),
            )}
          </Stack>
          <BotInput
            value={askedQuestion}
            loading={questionIsPending}
            onSubmit={handleSubmitQuestion}
            chips={chips}
          />
        </Stack>
      )}
    </Box>
  ) : null;
};
